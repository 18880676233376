@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";

.card-item{
  @include animate(opacity transform, 1s);
  text-align: center;
  padding: 10px 20px;
  opacity: 0;
  transform: translateY(50px);

  @include media('>=tablet') {
    max-width: 33.333%;
    width: 100%;
  }
  @include media('>=1240px') {
    max-width: 30%;
    width: 100%;
  }

  .shown & {
    opacity: 1;
    transform: translateY(0);
  }

  .img-wrap{
    @include size(200px);
    border-radius: 50%;
    margin: 0 auto 20px;
    overflow: hidden;

    @include media('>=tablet') {
      @include size(150px);
    }
    @include media('>=desktop') {
      @include size(170px);
    }
    @include media('>=1240px') {
      @include size(200px);
    }
    
  }

  h4{

  }

  .social-networks{
    justify-content: center;
    li{

      a{
        font-size: 25px;
        text-decoration: none;
        cursor: pointer;
    
        &:hover{
          text-decoration: none;
        }
      }
    }
  }

}