@import "../../assets/base/variables";
@import "../../assets/vendors/include-media";

.hero-content {
  text-align: center;
  font-weight: $font-weight-bold;

  .logo {
    display: block;
    max-width: 250px;
    margin: 0 auto 15px;

    @include media(">=480px") {
      max-width: 696px;
      margin: 0 auto;
    }

    @include media(">=desktop") {
      max-width: 64vh;
    }

    @include media(">=1240px") {
      // max-width: 696px;
    }
  }

  p {
    max-width: 860px;
    margin: 0 auto;
  }

  .btn {
    margin-bottom: 40px;

    @include media(">=tablet") {
      margin-top: -77px;
      margin-bottom: 64px;
    }
  }

  .countdown {
    font-size: 32px;
  }
}
