@import "icomoon-variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8l0pj3') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8l0pj3') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8l0pj3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-discord {
  &:before {
    content: $icon-discord; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-opensea {
  &:before {
    content: $icon-opensea; 
  }
}

