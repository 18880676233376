@import "../../assets/base/variables";
@import "../../assets/vendors/include-media";


.position-badge{
  background: $purple;
  padding: 6px 15px;
  border-radius: 15px;
  font-weight: $font-weight-bold;
  display: inline-block;
  vertical-align: top;

  @include media('>=tablet') {
    min-width: 90px;
  }
  @include media('>=desktop') {
    min-width: 110px;
    padding: 6px 20px;
  }
}