@import '../../assets/vendors/include-media';
@import '../../assets/vendors/normalize';
@import '../../assets/vendors/icomoon';

@import '../../assets/base/functions';
@import '../../assets/base/variables';
@import '../../assets/base/mixins';
@import '../../assets/base/helpers';
@import '../../assets/base/typography';
@import '../../assets/base/grid';
@import '../../assets/base/forms';


html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

* {max-height: 1000000px;}

body {
	color: $base-text-color;
	background: $base-background-color;
	font: 14px/1.428 $base-font-family;
	min-width: $base-min-width;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include media(">=tablet") {
		font: #{$font-size-base}/#{$line-height-base} $base-font-family;
  }
}

// adaptive images
img {
	max-width: 100%;
	width: 100%;
	height: auto;
	vertical-align: top;
	border: 0;
}

// google map fix
.gm-style img {max-width: none}

.wrapper {
	width: 	100%;
	position: relative;
	overflow: hidden;
}

.sr-only {
	@include size(1px);
	position: absolute;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;

	&.sr-only-focusable:active,
	&.sr-only-focusable:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}

body{
	// background-image: url('../../assets/media/start-1.jpg');
	// background-repeat: repeat-y;
	// background-size: contain;
	// background-position: center center;
}

.underlay{
	position: relative;
	padding-top: 50px;

	&::before{
		content: '';
		position: absolute;
		top: 90px;
		left: -20px;
		right: -20px;
		width: calc(100% + 40px);
		height: 150px;
		box-shadow: 0 -74px 40px 0 rgba($secondary, .5);
	}

	&::after{
		content: '';
		position: absolute;
		top: 90px;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: calc(100% - 90px);
		background: rgba($secondary, .5); 
	}
}