@import "../../assets/base/variables";
@import "../../assets/vendors/include-media";

.nav-main{


  li{
    padding: 20px 0;
  
    @include media('>=tablet') {
      padding: 0 19px;
    }

    @include media('>=desktop') {
      padding: 0 26px;
    }
  
    a{
      font-size: 30px;
      line-height: 1;
      font-weight: $font-weight-bold;
      cursor: pointer;
  
      @include media('>=tablet') {
        font-size: 20px;
      }
  
      &:hover{
        text-decoration: none;
        color: darken($primary, 30%);
      }
    }
  }
}