@import '../../assets/vendors/include-media';

.logo{

  .footer &{
    display: block;
    max-width: 342px;
    margin: 0 auto;
  
    @include media('>=tablet') {
      margin-bottom: 90px;
      margin-left: 0;
    }
  }
}