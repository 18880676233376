@import "../../assets/base/variables";
@import "../../assets/vendors/include-media";

.mobile-nav-opener {
  
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 100;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  text-indent: -9999px;
  overflow: hidden;
  padding: 0;
  border: 0;
  background: transparent;
  border-radius: 0;
  min-width: 1px;

  @include media('>=tablet') {
    display: none !important;
  }

  
  span {
    position: absolute;
    right: 5px;
    width: 31px;
    height: 3px;
    background: $primary;
    top: 8px;
    border-radius: 1px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 31px;
      height: 3px;
      background: $primary;
      top: 10px;
      border-radius: 1px;
    }

    &::after {
      top: 20px;
    }
  }
}