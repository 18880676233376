@import "../../assets/vendors/include-media";

.bg-video-holder{
  top: -70px;
  left: 0;
  right: 0;
  bottom: 0;
  // height: calc(100vh - 70px);
  height: 100vh;
  // transition: height 999999s;
  position: fixed;
  padding-bottom: 70px;
  transform: translateY(70px);
  z-index: 0;

  @mixin vh-fix {
    @media (max-device-aspect-ratio: 3/4) {
      height: calc(100vw * 1.333 - 9%);
    }
    @media (max-device-aspect-ratio: 2/3) {
      height: calc(100vw * 1.5 - 9%);
    }
    @media (max-device-aspect-ratio: 10/16) {
      height: calc(100vw * 1.6 - 9%);
    }
    @media (max-device-aspect-ratio: 9/16) {
      height: calc(100vw * 1.778 - 9%);
    }
  }

  @include media('>=tablet') {
    height: auto;
  }

  &.is-playing {
    .background-image {
      opacity: 0;
    }
  }
}

.video-content,
.background-image{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center right;

  @include media('>=desktop') {
    object-position: center right;
  }
}

.background-image {
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;

  transition: opacity .3s;
  @include media('>=480px') {
    object-position: center center;
    background-position: center right;
  }
  @include media('>=480px') {
    object-position: center center;
    background-position: center right;
  }
}