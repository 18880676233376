@import "../../assets/base/variables";
@import "../../assets/vendors/include-media";
li{
  padding: 0 17px;
  a{
    text-decoration: none;
    font-size: 29px;
    line-height: 1;

    &:hover{
      color: darken($primary, 30%);
      text-decoration: none;
    }
  }
}