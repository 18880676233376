// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
	font-family: $headings-font-family;
	font-weight: bold;
	line-height: 1.16;
	margin: 0 0 .5em;
	color: $headings-color;
}

h1, .h1 {
	font-size: $h1-font-size;

	@include media('>=tablet') {
		font-size: 40px;
	}
}

h2 {
	font-size: $h2-font-size;

	@include media('>=tablet') {
		font-size: 35px;
	}
}

h3, .h3 {
	font-size: $h3-font-size;

	@include media('>=tablet') {
		font-size: 30px;
	}
}

h4, .h4 {
	font-size: $h4-font-size;

	@include media('>=tablet') {
		font-size: 25px;
	}
}

h5, .h5{
	font-size: $h5-font-size;

	@include media('>=tablet') {
		font-size: 20px;
	}
}

h6, .h6 {
	font-size: $h6-font-size;

	@include media('>=tablet') {
		font-size: 16px;
	}
}

p {
	margin: 0 0 1.375em;
}

.small {
	font-size: $font-size-small; //16px
	line-height: $line-height-small; //19px

	@include media('>=tablet') {
		
	}
}

.medium {
	font-size: 16px; // 22px
	line-height: 1.2; // 19px

	@include media('>=tablet') {
		font-size: $font-size-medium; // 22px
		line-height: $line-height-medium; // 26px
	}
}

.lead {
	font-size: $font-size-large; // 32px
	line-height: $line-height-large; // 38px

	@include media('>=tablet') {
		
	}
}

a {
	@include animate(color background opacity border-color);
	color: $base-link-color;

	&:hover,
	&:focus {
		text-decoration: underline;
		color: $base-text-color;
	}
}
