$breakpoints: (
	'xs-phone': 320px,
	'phone': 414px,
	'tablet': 768px,
	'desktop': 1024px,
	'widescreen': 1280px
);

// keywords
$media-expressions: (
	'screen': 'screen',
	'print': 'print',
	'handheld': 'handheld',
	'landscape': '(orientation: landscape)',
	'portrait': '(orientation: portrait)',
	'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
	'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$black: #000;
$white: #fff;
$blue: #27AAE1;
$purple: #5A76B8;
$orange: #F7941D;

$primary: $white;
$secondary: $black;
$tertinary: $blue;

$color-palette: (
  "primary": $primary,
  "secondary": $secondary,
  "tertinary": $tertinary
);

$color-palette-buttons: (
  "primary": $primary,
  "secondary": $secondary,
	"tertinary": $tertinary
);


$base-font-sans-serif: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Comfortaa', 'Times', 'Baskerville', 'Georgia', serif;
$base-font: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

// This is th html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $primary;
$base-background-color: $secondary;
$font-size-small: 16px;
$line-height-small: 1.18; // 19px
$font-size-base: 18px;
$line-height-base: 1.23; // 21px
$font-size-medium: 22px;
$line-height-medium: 1.18; // 26px
$font-size-large: 32px;
$line-height-large: 1.187; // 38px
$base-font-family: $base-font-serif;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 320px;


// Liks
$base-link-color: $primary;

// Buttons 
$btn-color: $secondary;
$btn-background: $primary;
$btn-padding: 10px 30px;
$btn-font-size: 20px;

// Forms
$form-element-padding: 8px 10px;
$form-element-background-color: $primary;
$form-element-border-color: $secondary;
$form-element-focus-border-color: $secondary;
$placeholder-color: $secondary;

// Headers
$h1-font-size: 40px;
$h2-font-size: 30px;
$h3-font-size: 25px;
$h4-font-size: 20px;
$h5-font-size: 15px;
$h6-font-size: 12px;

$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-black:           900;

$headings-font-family: inherit;
$headings-color: inherit;

$animation-speed: 0.25s;
