@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";

.about-section{
  padding: 50px 0 60px;
  z-index: 5;
  position: relative;
  // opacity: 0;
  // transition: opacity 1s;

  


  @include media('>=tablet') {
    padding: 50px 0 100px;
  }

  // &.shown {
  //   opacity: 1;
  // }
  .container{
    position: relative;
    &::before{
      content: '';
      position: absolute;
      top: -2px;
      left: 18px;
      background-image: url('../../assets/media/single-star.svg');
      @include size(20px);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      @include media('>=tablet') {
        top: -11px;
        left: 18px;
      }
    
    }
  }
}