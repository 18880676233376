$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-plus: "\e904";
$icon-minus: "\e905";
$icon-arrow-down: "\e900";
$icon-discord: "\e901";
$icon-twitter: "\e902";
$icon-opensea: "\e903";

