@import "../../assets/base/variables";
@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";

.roadmap-item{
  @include animate(opacity transform, 1s);

  text-align: center;
  position: relative;
  padding-bottom: 85px;
  margin-bottom: 19px;
  opacity: 0;
  transform: translateY(50px);

  &.shown {
    opacity: 1;
    transform: translateY(0);
  }

  @include media('>=tablet') {
    padding-bottom: 130px;
  }

  &:last-child{
    margin-bottom: 0;
    padding-bottom: 0;

    &::after{
      display: none;
    }
  }

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../../assets/media/road-decoration.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 14px;
    height: 75px;

    @include media('>=tablet') {
      width: 20px;
      height: 105px;
    }
  }
  .roadmap-badge{
    min-width: 100px;
    font-size: 20px;
    font-weight: $font-weight-bold;
    padding: 8px 20px;
    background:$purple;
    border-radius: 17px;
    margin-bottom: 15px;
    display: inline-block;

    @include media('>=tablet') {
      min-width: 145px;
      font-size: 30px;
      padding: 10px 20px;
      margin-bottom: 20px;
    }
  }

  .images-holder{
    display: flex;
    flex-direction: column;
    
    @include media('>=tablet') {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: -28px;
      margin-right: -28px;
      width: calc(100% + 56px);
    }

    img{
      @include size(160px);
      border-radius: 50%;
      overflow: hidden;
      object-position: center top;
      object-fit: cover;

      @include media('>=tablet') {
        @include size(205px);
      }
    }
  }

  .img-wrap{
    
    // margin: 0 auto;
    margin: 18px 0;
    position: relative;

    @include media('>=tablet') {
      margin: 0 28px;
    }

    &:not(:last-of-type):nth-child(2){
      &::after,
      &::before{
        content: '+';
        position: absolute;
        font-size: 32px;
        line-height: 1;
        font-weight: $font-weight-normal;
        color: $primary;
        top: -33px;
        left: 50%;
        transform: translateX(-50%);

        @include media('>=tablet') {
          top: 50%;
          transform: translateY(-50%);
          transform: translateX(0);
          left: -39px;
        }
      }

      &::after{
        content: '=';
        left: 50%;
        right: 0;
        bottom: -39px;
        top: auto;

        @include media('>=tablet') {
          left: auto;
          right: -39px;
          top: 50%;
        }
      }
    }
  }

  h3{
    margin: 0 0 15px;
  }

  p{
    margin: 0 0 2em;
    &:last-child{
      margin: 0;
    }
  }
}