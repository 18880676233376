@import "../../assets/vendors/include-media";

.card-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media('>=tablet') {
    justify-content: space-between;
    // margin-left: -20px;
    // margin-right: -20px;
    // width: calc(100% + 40px);
  }
}

