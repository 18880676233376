@import "../../assets/base/variables";
@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";

.counterWrap {
  text-align: center;
  font-weight: 20px;
  line-height: 1.2;
  font-weight: $font-weight-bold;

  .btn{
    margin-bottom: 60px;
    display: inline-block;
  }

  p{
    margin: 0;
  }
}

.counter-title{
  display: inline-block;
  font-size: 22px;
  font-weight: $font-weight-bold;
  margin-bottom: 22px;
}

.counter-holder{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .count{
    font-size: 100px;
    line-height: 1;
    font-family: $base-font;
    padding: 0 10px;
    min-width: 110px;
  }
}

.counter-btn{
  @include size(37px);
  border-radius: 50%;
  background-color: rgba($primary, .7);
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    text-decoration: none;
  }
}

.btn-prev,
.btn-next{
  color: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-size: 70px;
  // font-weight: $font-weight-bold;
  // &::after{
  //   content: '-';
  //   line-height: 1;
  //   margin-top: -10px;
  // }
}
.btn-next{
  // font-size: 43px;
  
  // &::after{
  //   content: '+';
  //   margin-top: 0;
  // }
}