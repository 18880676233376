@import "../../assets/vendors/include-media";

.team-section{
  position: relative;
  padding: 60px 0;
  z-index: 5;

  @include media('>=tablet') {
    padding: 100px 0;
  }
}