// forms
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

.form-group {
  margin-bottom: 27px;

  &.form-primary {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 32px;
    }
  }
}

.group-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

label {
  display: block;
  font-size: $font-size-base; // 20px
  line-height: $line-height-base; // 28px
  font-weight: $font-weight-bold;
  margin-bottom: 9px;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
//input[type='url'],
// input[type='date'],
textarea {
  @include animate(box-shadow border-color background, 0.15s, ease-out);
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 2px solid $form-element-border-color;
  border-radius: 4px;
  background-color: $form-element-background-color;
  padding: $form-element-padding;
  font-size: 20px; // 20px
  line-height: 1.2; // 22px
  font-weight: $font-weight-normal;
  color: $secondary;

  &:focus {
    border-color: $form-element-focus-border-color;
    outline: none;
  }

  @include placeholder {
    color: $placeholder-color;
  }
}

select {
  @include animate(box-shadow border-color background, 0.15s, ease-out);
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  appearance: none;
  display: block;
  width: 100%;
  border: 2px solid $form-element-border-color;
  border-radius: 4px;
  background: $form-element-background-color url("data:image/svg+xml,%3Csvg width='11' height='5' xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 11 5'%3E%3Cpath d='M.087 0l5 5 5-5z' fill='%23333333'%3E%3C/path%3E%3C/svg%3E") no-repeat right 9px center;
  background-size: 11px 5px;
  padding: $form-element-padding;
  padding-right: 40px;
  font-size: 20px; // 20px
  line-height: 1.2; // 22px
  font-weight: $font-weight-normal;
  color: $base-text-color;
  outline: none;

  &::-ms-expand {
    display: none;
  }

  &[multiple] {
    background: $form-element-background-color;
  }

  &:focus {
    border-color: $form-element-focus-border-color;
    // box-shadow: 0 0 5px rgba($black, .4);
  }
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin: 0;
}

form button[type="button"],
form input[type="button"],
input[type="reset"],
button[type="reset"],
input[type="submit"],
button[type="submit"] {
  &:not(.btn):not(.search-btn) {
    @include animate(border-color box-shadow background opacity color);
    display: inline-block;
    vertical-align: top;
    font-size: 20px; // 20px
    line-height: 1.2; //22px
    color: $btn-color;
    text-align: center;
    text-decoration: none;
    border-radius: 0;
    border: none;
    border-width: 2px;
    background: $primary;
    box-shadow: none;
    padding: 10px 30px;
    margin: 0;

    &:hover {
      background: darken($secondary, 10%);
    }
  }
}
