@import "../../assets/base/mixins";

.title-decorated{
  @include animate(opacity transform, 1s);
  display: block;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto 43px;
  padding-top: 55px;
  position: relative;
  opacity: 0;
  transform: translateY(50px);

  .shown & {
    opacity: 1;
    transform: translateY(0);
  }

  &::before{
    @include animate(opacity transform, .7s);
    content: '';
    position: absolute;
    background-image: url('../../assets/media/title-decor.svg');
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 215px;
    height: 33px;
    opacity: 0;
    transition-delay: .7s;

    .shown & {
      opacity: 1;
    }
  }
}