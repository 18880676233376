@import "../../assets/base/variables";
@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";


.text-block{
  @include animate(opacity transform, 1s);
  padding-top: 60px;
  position: relative;
  margin-bottom: 50px;
  opacity: 0;
  transform: translateY(50px);

  @include media('>=tablet') {
    margin-bottom: 95px;
    padding-top: 80px;
  }

  &.shown {
    opacity: 1;
    transform: translateY(0);
  }

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    @include size(22px);
    background-image: url('../../assets/media/single-star.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  h4{

  }

  p{

  }
}