@import "../../assets/vendors/include-media";

.characters-group {
  position: absolute;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.7s;
  &:nth-child(1) {
    left: 48.8%;
    top: 0;
    z-index: 5;
    width: 55.5%;
    @include media(">=480px") {
      width: 40.5%;
    }
  }
  &:nth-child(2) {
    left: 29.1%;
    top: 3.6%;
    z-index: 4;
    width: 44.3%;
    animation-delay: 0.2s;
    @include media(">=480px") {
      left: 31.1%;
      top: 4.6%;
      width: 32.3%;
    }
  }
  &:nth-child(3) {
    left: 16.1%;
    top: 4.6%;
    z-index: 3;
    width: 42.1%;
    animation-delay: 0.3s;
    @include media(">=480px") {
      left: 18.1%;
      z-index: 3;
      width: 32.1%;
    }
  }
  &:nth-child(4) {
    left: 11.4%;
    top: 3.1%;
    z-index: 2;
    width: 34.8%;
    animation-delay: 0.4s;
    @include media(">=480px") {
      left: 12.2%;
      z-index: 2;
      width: 24.8%;
    }
  }
  &:nth-child(5) {
    left: 5.7%;
    top: 5.6%;
    z-index: 1;
    width: 12.6%;
    animation-delay: 0.5s;
    @include media(">=480px") {
      left: 4.7%;
      top: 7.6%;
      z-index: 1;
      width: 9.6%;
    }
  }
  &:nth-child(6) {
    top: 3.6%;
    right: -5.5%;
    z-index: 4;
    width: 34.4%;
    animation-delay: 0.2s;
    @include media(">=480px") {
      top: 5.6%;
      right: 5.5%;
      width: 25.4%;
    }
  }
  &:nth-child(7) {
    top: 2.9%;
    right: -20.5%;
    width: 37.3%;
    z-index: 3;
    animation-delay: 0.3s;
    @include media(">=480px") {
      right: -8.5%;
      width: 27.3%;
    }
  }

  &:nth-child(8) {
    top: -0.1%;
    right: -19.5%;
    z-index: 2;
    width: 27.9%;
    animation-delay: 0.4s;
    @include media(">=480px") {
      right: -10.6%;
      width: 20.9%;
    }
  }
  &:nth-child(9) {
    top: 3.9%;
    right: -8.1%;
    z-index: 1;
    width: 14.1%;
    animation-delay: 0.5s;
    @include media(">=480px") {
      top: 5.9%;
      right: -5.1%;
      width: 10.1%;
    }
  }

  &.shown {
    opacity: 1;
  }
}
