@import "../../assets/base/variables";
@import "../../assets/base/helpers";

.position-list{
  @extend %listreset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;

  li{
    padding: 5px;

    &:nth-child(even){
      .position-badge{
        background: $orange;
      }
    }
  }
}