@import "../../assets/base/variables";
@import "../../assets/vendors/include-media";

.footer {
  position: relative;
  background: $purple;
  z-index: 5;
  padding: 72px 0;

  a {
    text-decoration: none;

    &:hover{
      text-decoration: none;
      color: darken($primary, 30%);
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: calc(100% - 1px);
    left: 0;
    right: 0;
    background-image: url("../../assets/media/triangle.svg");
    background-repeat: repeat-x;
    background-position: top left;
    background-size: contain;
    width: 100%;
    height: 12px;
  }

  .container {
    max-width: 1770px;
  }

  .row {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);

    @include media(">=tablet") {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .column,
  .content-column {
    padding: 20px;
  }

  .column {
    @include media(">=tablet") {
      max-width: 640px;
      width: 100%;
      margin-right: 40px;
    }
  }

  .privacy {
    font-size: 9px;
    text-align: center;

    @include media(">=tablet") {
      max-width: 640px;
      width: 100%;
      text-align: left;
    }
  }
}
