@import "../../assets/vendors/include-media";

.container.characters-container{
  padding: 0;
  max-width: none;
}

.characters-holder{
  display: flex;
  min-height: 69vw;
  position: relative;
  overflow: hidden;

  @include media('>=480px') {
    min-height: 50vw;

  }

  @include media('>=1920px') {
    // min-height: 1000px
    min-height: 52vw;
  }
}