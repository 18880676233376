@import '../../assets/vendors/include-media';

.content-column{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  @include media('>=tablet') {
    align-items: flex-start;
    flex-direction: row;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    max-width: 670px;
  }
}