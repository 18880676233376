@import "../../assets/base/variables";
@import "../../assets/base/helpers";
@import "../../assets/vendors/include-media";

.nav-main{
  @extend %listreset;
  display: flex;
  flex-direction: column;
  text-align: center;

  @include media('>=tablet') {
    flex-direction: row;
    text-align: left;  
  }
  @include media('>=widescreen') {
    margin-right: 30px; 
  }
}