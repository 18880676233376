@import "../../assets/base/variables";
@import "../../assets/vendors/icomoon";
@import "../../assets/vendors/include-media";

.hero{
  padding: 100px 0;
  // padding: 70px 0 120px;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @include media('>=tablet') {
    padding: 147px 0;
  }
  @include media('>=desktop') {
    padding: 13vh 0;
  }

  .container{

  }
}

.scroll-down{
  color: $blue;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  padding-bottom: 30px;
  font-size: 15px;
  font-weight: $font-weight-bold;
  cursor: pointer;
  
  @include media('>=768px') {
    bottom: 9px;
  }

  @include media('>=tablet') {
    bottom: 9px;
    padding-bottom: 52px;
    font-size: 16px;
  }

  &::after{
    font-family: $icomoon-font-family;
    content: $icon-arrow-down;
    font-size: 30px;
    line-height: 1;
    position: absolute;
    bottom: 0;
    left: 28px;
    @include media('>=tablet') {
      font-size: 55px;
      left: 20px;
    }
  }

  &:hover{
    text-decoration: none;
  }
}