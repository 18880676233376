@import '../../assets/base/variables';
@import '../../assets/vendors/include-media';
@import '../../assets/base/mixins';

.nav-item{
  max-width: 100%;
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media('>=tablet') {
    max-width: 100%;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    margin-bottom: 0;
    align-items: flex-start;
  }
  @include media('>=desktop') {
    max-width: 50%;
  }

  &:nth-child(2){
    order: -1;

    @include media('>=tablet') {
      order: 0;
    }
  }

  h5{
    position: relative;
    padding-top: 12px;
    margin-bottom: 35px;
    display: flex;

    @include media('>=tablet') {
      margin-bottom: 25px;
    }

    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: -19px;
      @include size(15px);
      background-image: url('../../assets/media/single-star.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
    a{
      font-size: $h5-font-size;

      @include media('>=tablet') {
        font-size: 20px;
      }
    }
  }

  a{
    font-size: 20px;
  }

  .social-networks{
    margin-left: -17px;
    margin-right: -17px;
    width: calc(100% + 34px);
    li{

      a{
        font-size: 35px;
        text-decoration: none;
        cursor: pointer;
    
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}