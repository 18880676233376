.container {
	width: 100%;
	max-width: 1031px; // 981px // gutters 50px/2
	padding-right: 16px;
	padding-left: 16px;
	margin-right: auto;
	margin-left: auto;

  @include media('>=desktop') {
    padding-right: 30px;
		padding-left: 30px;
  }
}

.container-lg {
	max-width: 1718px; // 1668px // gutters 50px/2
  padding-right: 25px;
  padding-left: 25px;
}

.container-fluid {
  max-width: none;

  @media(min-width: 1240px){
  }

  @media(min-width: 1920px){
    max-width: 1920px;
    padding-left: 0;
    padding-right: 0;
  }

}
