@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";

.info-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include media(">=tablet") {
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .image-holder {
    @include animate(opacity transform, 1s);
    max-width: 368px;
    padding: 41px 46px 45px 30px;
    position: relative;
    opacity: 0;
    transform: translateY(50px);

    .shown & {
      opacity: 1;
      transform: translateY(0);
    }

    &::before,
    &::after {
      @include animate(opacity, 0.7s);
      content: "";
      position: absolute;
      top: 1px;
      left: -45px;
      background-image: url("../../assets/media/img-decoration-1.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      // width: 236px;
      // height: 174px;
      width: 64%;
      height: 26%;
      opacity: 0;
      transition-delay: 0.7s;

      .shown & {
        opacity: 1;
      }
    }

    &::after {
      background-image: url("../../assets/media/img-decoration-2.svg");
      top: auto;
      left: auto;
      bottom: 0;
      right: -57px;
      // width: 350px;
      // height: 373px;
      width: 95%;
      height: 77%;
    }

    img {
      border-radius: 10px;
    }
  }

  .content-holder {
    @include animate(opacity transform, 1s);
    // max-width: 590px;

    padding: 25px 0;
    opacity: 0;
    transform: translateY(50px);

    .shown & {
      opacity: 1;
      transform: translateY(0);
    }

    @include media(">=tablet") {
      padding: 25px 0 25px 20px;
      max-width: 59%;
    }

    h3 {
    }

    p {
      line-height: 1.24;
      margin: 0 0 1.2em;
      &:last-child {
        margin: 0;
      }
    }
  }
}
