@import "../../assets/vendors/include-media";

.roadmap-section{
  padding: 60px 0;
  z-index: 5;
  position: relative;

  @include media('>=tablet') {
    padding: 100px 0 100px;
  }
}