@import "../../assets/base/variables";
@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";


@each $palette-color-name, $color in $color-palette-buttons {
  
  .btn {

    &.btn-#{$palette-color-name} {
      background: $color;
      border-color: $color;
      color: $white;

      &:hover {
        background: darken($color, 10%);
        border-color: darken($color, 10%);
      }
    }

    &.btn-pill-#{$palette-color-name} {
      background: transparent;
      border-color: $color;
      color: $color;
      text-align: center;

      &:hover {
        color: $white;
      }

      &:after {
        background: $color;
      }
    }
  }
}

.btn {
  @include animate(border-color box-shadow background opacity color);
  display: inline-block;
  vertical-align: top;
  font-size: $btn-font-size; // 16px
  line-height: 1; // 16px
  font-weight: $font-weight-bold;
  color: $btn-color;
  text-decoration: none;
  text-align: center;
  border: none;
  border-radius: 20px;
  padding: $btn-padding;
  margin: 0 0 10px;
  position: relative;
  min-width: 100px;
  max-width: 150px;

  @include media('>=tablet') {
  max-width: 190px;
    
  }

  &:hover {
    text-decoration: none;
  }

  &[class*="btn-outline"] {

    &:hover {
      text-decoration: none;
      background: $primary;
      border-color: $primary;
      color: $white;
    }
  }

  &.btn-decorated,
  &.btn-decorated-alt {
    font-size: 15px;
    border-radius: 9px;
    padding: 10px 15px;
    background-color: $primary;

    @include media('>=tablet') {
      font-size: 20px;
      padding: 10px 19px;
    }

    &:hover{
      background-color: darken($primary, 30%);
      text-decoration: none;

      &::before{
        opacity: .7;
      }
    }

    &::before{
      @include animate(opacity);
      content: '';
      position: absolute;
      top: -15px;
      left: -27px;
      right: 0;
      bottom: 0;
      width: calc(100% + 50px);
      height: calc(100% + 50px);
      background-image: url('../../assets/media/btn-stars-1.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      @include media('>=tablet') {
        width: calc(100% + 55px);
        height: calc(100% + 55px);
      }
    }
  }
  &.btn-decorated-alt {
    font-size: 17px;

    @include media('>=tablet') {
      font-size: 24px;
      padding: 10px 19px;
    }

    &::before{
      top: -15px;
      left: -27px;
      width: calc(100% + 60px);
      height: calc(100% + 52px);
      background-image: url('../../assets/media/btn-stars-2.svg');

      @include media('>=tablet') {
        width: calc(100% + 70px);
        height: calc(100% + 55px);
      }
    }
  }
}