@import "../../assets/base/variables";
@import "../../assets/base/mixins";
@import "../../assets/vendors/include-media";

header {
  padding: 16px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @include media('>=tablet') {
    padding: 36px 0;
  }

  .container{
    max-width: 1494px;
    position: relative;
  }

  .header-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-height: 50px;

    @include media('>=tablet') {
      flex-direction: row;
      min-height: 60px;
    }
    
    @include media('>=desktop') {
      max-width: 595px;
      margin: 0 auto;
    }
  }

  &.nav-active .nav-drop{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &.nav-active .mobile-nav-opener {
    span {
      @include animate(transform);
      right: 0;
      top: 18px;
      width: 100%;
      height: 3px;
      border-radius: 0;
      transform: rotate(45deg);
      background: $primary;

      &::before {
        opacity: 0;
      }

      &::after {
        @include animate(transform);
        right: 0;
        top: 1px;
        width: 100%;
        height: 3px;
        border-radius: 0;
        transform: rotate(-90deg);
        background: $primary;
      }
    }
  }

  .nav-wrap{

  }

  .nav-drop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;



    @include media('<=767px') {
      @include animate(opacity visibility transform);
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: -34px;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translateY(-100px);
      background: rgba($secondary, .8);
      width: 100%;
      height: calc(100vh + 34px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 15;
    }

    
  }

  .btn{
    flex-shrink: 0;
    margin: 0;
    position: relative;
    z-index:10;
    margin-left: 11px;

    @include media('>=tablet') {
      margin-right: 15px;
      margin-left: 0;
    }

    @include media('>=desktop') {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      margin-right: 0;
    }
  }

}